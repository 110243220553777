export default {
  search (array, string) {
    return array.filter(o => Object.keys(o).some(k => {
      if (typeof o[k] === 'string') {
        return o[k].toLowerCase().includes(string.toLowerCase())
      }
    }))
  },
  searchCandidate (array, string) {
    return array.filter(o => Object.keys(o.profile).some(k => {
      if (typeof o.profile[k] === 'string') {
        return o.profile[k].toLowerCase().includes(string.toLowerCase())
      }
    }))
  },

  searchMatrix (array, name, group, cn, status) {
    let result = array
    if (name) {
      result = array.filter(o => Object.keys(o.candidate).some(k => {
        if (typeof o.candidate[k] === 'string') {
          return o.candidate[k].toLowerCase().includes(name.toLowerCase())
        }
      }))
    }
    if (group) {
      result = result.filter(o => Object.keys(o.group.group).some(k => {
        if (typeof o.group.group[k] === 'string') {
          return o.group.group[k].toLowerCase().includes(group.toLowerCase())
        }
      }))
    }
    console.log('result', result)
    return result
  }
}
