<template>
  <div class="main">
    <div class="group-candidate content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidate</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Candidate Group</h4>
          <div class="action">
            <a-button type="primary" @click="groupModal"><PlusOutlined /> Add Group</a-button>
          </div>
        </a-col>
      </a-row>

      <a-row class="combo-lists">
        <div class="body">
          <a-row class="filter">
            <!-- <div  v-if="hasSelected">
              <a-button type="primary" class='deletbtn' @click="deleteGroupCourse">Delete</a-button>
            </div> -->
            <div></div>
            <a-input-search v-model:value="searchString" placeholder="Search here ..." @search="onSearch"/>

          </a-row>
          <div class="users">
            <a-table :columns="columns" :data-source="groups" class="ant-table-striped"
              :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
              :pagination="{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
              }"
              rowKey="_id"
            >
              <template #nameSection="{ record }">
                <router-link :to="'/client/candidate_group/add/'+record._id+loginAs">
                  {{record.name}}
                </router-link>
              </template>
              <template #action="{ record, index }">
                <div class="actionblock">
                  <img src="../../../assets/images/editicon.svg" @click="editGroupModal(record, index)" />
                  <img src="../../../assets/images/deleteicon.svg" @click="confirmDelete(record, index)" />
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </a-row>
    </div>
    <!-- add group course modal -->
    <a-modal v-model:visible="visible" centered title="Add Group" :maskClosable=false :destroyOnClose=true class="groupmodal">
      <template #footer>
        <a-button key="submit" type="primary" :loading="loading" @click="validateGroupForm">{{(edit ? 'Update' : 'Create')}}</a-button>
      </template>
      <a-form ref="groupFormRef" :model="groupForm" :rules="groupRules" :hideRequiredMark="true" layout="vertical" :wrapper-col="{ span: 24 }" :label-col="{ span: 24 }">
        <a-form-item label="Name" required has-feedback name="name">
          <a-input v-model:value="groupForm.name" size="large" type="text" placeholder="Enter Name" allow-clear autocomplete="off" />
        </a-form-item>

        <a-form-item label="Description" name="description">
          <a-textarea v-model:value="groupForm.description" placeholder="Description" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { CloseCircleOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { notification, Modal } from 'ant-design-vue'

import groupService from '../../../services/candidateGroup'
import validate from '../../../services/validator'
import searchService from '../../../services/filter'
import commonServices from '../../../services/common'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}
type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const loading = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const edit = ref<boolean>(false)
    const editIndex = ref<number>(0)
    const groups = ref<Array<Group>>()
    const groupData = ref<Array<Group>>()
    const groupFormRef = ref()
    const groupRules = validate.groupCourseValidation
    const searchString = ref<string>('')
    const onSearch = async (searchValue: string) => {
      groups.value = searchService.search(groupData.value, searchValue)
    }
    const groupForm: UnwrapRef<Group> = reactive({
      name: '',
      description: '',
      client: commonServices.getCurrentProfile()._id
    })
    const groupModal = async () => {
      visible.value = true
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
        groupData.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const addGroup = async (values: Group) => {
      try {
        loading.value = true
        const responce = await groupService.createGroup(values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          responce.data.total_course = 0
          groups.value.push(responce.data)
          visible.value = false
          loading.value = false
          groupFormRef.value.resetFields()
          await notify('Success', 'Group added successfully', 'success')
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateGroup = async (values: Group) => {
      try {
        loading.value = true
        const responce = await groupService.updateGroup(values._id, values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          responce.data.total_course = 0
          groups.value[editIndex.value] = responce.data
          visible.value = false
          loading.value = false
          groupFormRef.value.resetFields()
          await notify('Success', 'Group updated successfully', 'success')
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const editGroupModal = async (group, index) => {
      edit.value = true
      editIndex.value = index
      Object.assign(groupForm, group)
      groupModal()
    }
    const validateGroupForm = async () => {
      groupFormRef.value.validate().then(() => {
        if (edit.value) {
          updateGroup(groupForm)
        } else {
          addGroup(groupForm)
        }
      }).catch((error: ValidateErrorEntity<Group>) => {
        console.log('error', error)
      })
    }
    const deleteGroup = async (group, index) => {
      try {
        await groupService.deleteGroup(group._id)
        groups.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDelete = async (group, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteGroup(group, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const columns = [
      {
        title: 'Group Name',
        onFilter: (value, record) => record.courses.indexOf(value) === 0,
        sorter: (a, b) => a.courses.length - b.courses.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'No of Candidates',
        dataIndex: 'total_course'
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    // select table rows checkbox
    // const selectedRowKeys = ref<Key[]>([])
    // const onSelectChange = (changableRowKeys: Key[]) => {
    //   selectedRowKeys.value = changableRowKeys
    // }
    // const rowSelection = computed(() => {
    //   return {
    //     selectedRowKeys: unref(selectedRowKeys),
    //     onChange: onSelectChange,
    //     hideDefaultSelections: true,
    //     selections: [
    //       {
    //         key: 'all-data',
    //         text: 'Select All Data',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       },
    //       {
    //         key: 'odd',
    //         text: 'Select Odd Row',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //             if (index % 2 !== 0) {
    //               return false
    //             }
    //             return true
    //           })
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       },
    //       {
    //         key: 'even',
    //         text: 'Select Even Row',
    //         onSelect: (changableRowKeys: Key[]) => {
    //           let newSelectedRowKeys = []
    //           newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //             if (index % 2 !== 0) {
    //               return true
    //             }
    //             return false
    //           })
    //           selectedRowKeys.value = newSelectedRowKeys
    //         }
    //       }
    //     ]
    //   }
    // })
    // const hasSelected = computed(() => selectedRowKeys.value.length > 0)
    const deleteGroupCourse = async () => {
      console.log('delete')
    }
    onMounted(() => {
      getGroups()
    })
    return {
      groupModal,
      loading,
      visible,
      validateGroupForm,
      groupFormRef,
      groupRules,
      groupForm,
      columns,
      searchString,
      // rowSelection,
      deleteGroupCourse,
      // hasSelected,
      onSearch,
      groups,
      editGroupModal,
      edit,
      confirmDelete,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.group-candidate {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 0px 10px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
    }
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 16px;
      text-align: left;
      margin: 0;
    }
    .action {
      button {
        border: 1px solid #2ecc71;
        background: transparent;
        border-radius: 20px;
        box-shadow: none;
        span {
          color: #646464;
          font-family: "TT Norms Pro Regular";
        }
        .anticon {
          color: #2ecc71;
        }
      }
    }
  }
  .combo-lists {
    .body {
      background: #ffffff;
      min-height: 200px;
      box-shadow: 0px 5px 10px #00000003;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px;
      width: 100%;
      .title {
        color: #707070;
        float: left;
        font-size: 20px;
        text-transform: capitalize;
        font-family: "TT Norms Pro Medium";
      }
      .filter {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 0 0;
        .noofentries {
          display: flex;
          align-items: center;
          .ant-select {
            margin: 0 5px;
          }
        }
        .ant-input-affix-wrapper {
          width: 200px;
        }
        .ant-select,
        .ant-btn {
          float: left;
        }
        .ant-btn {
          background: #7EAF1A;
          border-color: #7EAF1A;
          border-radius: 20px;
          font-family: "TT Norms Pro Medium";
          &.deletbtn{
          background-color: #ff7875;
          border: none;
          border-radius: 5px;
          }
        }
      }
      .users {
        margin-top: 20px;
        font-family: "TT Norms Pro Medium";
        .ant-table {
          color: #646464;
          .ant-table-thead {
            tr {
              th {
                background: #e6e6e6;
                color: #646464;
                &:first-child {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                &:last-child {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
              }
            }
          }
          .ant-table-tbody {
            tr {
              td {
                border-bottom: none;
                &:nth-child(2) {
                  color: #7EAF1A;
                }
              }
            }
            .actionblock {
              img {
                width: 30px;
                height: 30px;
                cursor: pointer;
                margin: 0 5px;
              }
            }
          }
        }
        .ant-pagination {
          .ant-pagination-options {
            // display: none;
          }
          .ant-pagination-prev .ant-pagination-item-link,
          .ant-pagination-next .ant-pagination-item-link {
            border-radius: 50%;
            background-color: #ededed;
            border: none;
          }
          .ant-pagination-item {
            background-color: #ededed;
            margin-right: 0px;
            border: none;
            font-family: "TT Norms Pro Regular";
          }
          li:nth-child(2) {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          li:nth-last-child(3n) {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
          .ant-pagination-next {
            margin-left: 8px;
          }
          .ant-pagination-item-active {
            background-color: #ededed;
            a {
              background: #6f64f8;
              color: #fff;
              border-radius: 50%;
            }
          }
        }
        .totalentries {
          font-family: "TT Norms Pro Regular";
          text-align: left;
          margin: -40px 0 0 0;
          color: #646464;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
.groupmodal {
  width: 420px !important;
  .ant-modal-title {
    font-family: "TT Norms Pro Medium";
    color: #646464;
    text-align: center;
  }
  .ant-modal-close {
    .anticon {
      color: #f6596f;
      border: 1px solid #f6596f;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .ant-modal-body {
    .ant-form {
      width: 80%;
      margin: auto;
      label {
        font-family: "TT Norms Pro Regular";
        color: #646464;
      }
      .ant-form-item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding: 10px 24px;
    .ant-btn-primary {
      display: block;
      width: 80%;
      margin: 0 auto 10px;
      background-color: #72cf97;
      border-color: #72cf97;
      font-family: "TT Norms Pro Medium";
      text-shadow: none;
    }
  }
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
