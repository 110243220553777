import axios from 'axios'

export default {
  createGroup (data) {
    return axios.post(process.env.VUE_APP_CANDIDATE_GROUP, data)
  },

  updateGroup (id, data) {
    return axios.put(process.env.VUE_APP_CANDIDATE_GROUP + '/' + id, data)
  },

  getGroupDetails (id) {
    return axios.get(process.env.VUE_APP_CANDIDATE_GROUP + '/' + id)
  },

  getGroup (clientId) {
    return axios.get(process.env.VUE_APP_CANDIDATE_GROUP + '/by_client/' + clientId)
  },

  deleteGroup (id) {
    return axios.delete(process.env.VUE_APP_CANDIDATE_GROUP + '/' + id)
  },

  addCandidates (data) {
    return axios.post(process.env.VUE_APP_GROUP_CANDIDATES, data)
  },

  getGroupCandidates (id) {
    return axios.get(process.env.VUE_APP_CANDIDATE_GROUP + '/candidates/' + id)
  },

  getUnassignedCandidates (id, clientId) {
    return axios.get(process.env.VUE_APP_CANDIDATE_GROUP + '/candidates/unassigned/' + id + '/' + clientId)
  },

  assignCandidates (data) {
    return axios.post(process.env.VUE_APP_GROUP_CANDIDATES + '/multiple', data)
  },

  deleteGroupCandidate (id) {
    return axios.delete(process.env.VUE_APP_CANDIDATE_GROUP + '/' + id)
  }

}
